<script setup>
import { onBeforeMount, onMounted, toRaw, toRef, computed, watch, ref } from "vue";
import env from "@/helpers/Environment";
import _ from "lodash";
import { useRouter, useRoute } from 'vue-router'
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import { useStoreAccount } from '@/stores/store.account';
import { useStoreSite } from '@/stores/store.site';
import ColumnGroup from "primevue/columngroup";
import Utils from "./helpers/Utils";
const storeAccount = useStoreAccount();
const storeSite = useStoreSite();
const debugFlag = false;

const router = useRouter()
const route = useRoute()
const stageEnv = env.STAGE_ENV;
const cTitle = computed(() => toRaw(storeSite.title));
const cSiteLayout = computed(() => toRaw(storeSite.layoutView));
const cIsMobile = computed(() => storeSite.isMobile);


const debounceResizeWindow = _.debounce(() => {
  let isMobile = storeSite.checkIsMobile();
  //close sidebar if mobile, else open sidebar
  storeSite.sideBarFlag = !isMobile
}, 100);

// storeSite.$subscribe((mutation, state) => {
//   console.log(mutation, state)
//   if(!state.wasLoggedIn){
//   }
// })

window.addEventListener("resize", debounceResizeWindow);
debounceResizeWindow();

window.addEventListener('storage', async (e) => {
  if (storeAccount.user.skipCheck) return
  if (e.key === Constant.STORAGE_WAS_LOGGED_IN && e.newValue === 'false') {
    await storeAccount.logout();
    window.location.reload();
  }
});

const updateLoginStatus = async (e) => {
  if (storeAccount.user.skipCheck) return
  if (typeof e?.data !== 'string') return
  const wasLoggedIn = storeSite.wasLoggedIn || localStorage.getItem(Constant.STORAGE_WAS_LOGGED_IN) == "true";
  if (e?.data === 'false' && wasLoggedIn) {
    await storeAccount.logout();
    window.location.reload();
  }
};

window.addEventListener("message", updateLoginStatus)

onBeforeMount(async () => {
  // loynote: set title to empty so teleport will not append to it.
  document.title = "";
});

onMounted(() => {
  window.onpopstate = event => {

    const isLoggedIn = storeAccount.user?.isLoggedIn;
    console.log("event", event)
    console.log("isLoggedIn", isLoggedIn)
    console.log("route", route.path)


    if (!isLoggedIn && route.path == "/auth/login") {
      window.location.href = "/";
    }
  };
})


</script>


<template>
  <div class="debug" v-if="debugFlag">
    storeSite.init {{ storeSite.init }} <br>
    firstName: {{ storeAccount.user.userId }}<br>
    isLoggedIn: {{ storeAccount.isLoggedIn }}
    <!-- displayPicture: {{storeAccount.user.displayPicture}} -->
    <!-- <small>{{ storeSite }}</small> -->
    <small>{{ storeAccount.user.accessToken }}</small>
  </div>
  <div class="ribbon" v-if="stageEnv != 'prod'">{{ stageEnv }}</div>
  <Toast />
  <ConfirmDialog class="confirmDialog-custom">
    <template #message="slotProps">
      {{ slotProps.message.message }}
    </template>

  </ConfirmDialog>

  <teleport to="head title">{{ cTitle }}</teleport>

  <div :class="['wrapper', cIsMobile ? 'mobile' : '']">
    <!-- {{store.state.site.mobile}} -->
    <component :is="cSiteLayout" />
  </div>
  <iframe id="iframe-session" :src="`${env.OIDC_URL}/check-session`" style="display:none"></iframe>
</template>

<style lang="scss">
.confirmDialog-custom {
  width: 300px;
}

@media screen and (min-width:820px) {
  .confirmDialog-custom {
    width: 500px;
  }
}

@media screen and (min-width:$break-large) {
  .confirmDialog-custom {
    width: 450px;
  }
}

// .confirmDialog-custom {
//   :deep(.p-dialog .p-dialog-footer button) {
//     background-color: pink;
//   }
// }

.debug {
  color: red;
  position: absolute;
  width: 800px;
  top: 50px;
}

.wrapper {
  width: 100%;
  height: 100%;
}
</style>