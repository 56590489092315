import moment from 'moment';
import *  as _date from 'date-fns'
import _ from 'lodash';
import { useStoreAccount } from "@/stores/store.account";
import { countriesData } from "@/assets/json/all-country.js";


const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]';
const lowerKeys = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((o) => lowerKeys(o));
  }
  return Object.keys(obj).reduce((prev, curr) => {
    prev[curr.toLowerCase()] = lowerKeys(obj[curr]);
    return prev;
  }, {});
};
const nth = (number) => {
  return number > 0
    ? ["th", "st", "nd", "rd"][
        (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
      ]
    : "";
};

export const formatDate = (d) => {
    const datetime = d.toLocaleString('en-SG', { timeZone: 'Asia/Singapore' });
    return  `${datetime.split(",")?.[0]}`;
}
export const formatDateTime = (d) => {
  return d.toLocaleString('en-SG', { timeZone: 'Asia/Singapore' });
}

// place functions independent of project here
export default {
  convertObjToReadableStr: (obj) => {
    obj = cleanObject(_.cloneDeep(obj));
    let result = "";

    function formatKey(key) {
      return key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }

    function processArray(key, array, indentLevel) {
      const sectionTitle = `${formatKey(key)} Information:`;
      result += " ".repeat(indentLevel) + sectionTitle + "\n";
      result += " ".repeat(indentLevel) + "--------------------------------------\n";
      array.forEach((item) => {
        for (let innerKey in item) {
          processKeyValue(innerKey, item[innerKey], indentLevel + 2, key);
        }
        result += "\n";
      });
    }

    function processKeyValue(key, value, indentLevel = 0, parentKey = "") {
      const formattedKey = formatKey(key);
      const indent = " ".repeat(indentLevel);
      if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          if (key == "proofOfIdentity" || key == "attachments") {
            result += " ".repeat(indentLevel) + formatKey(key) + ":\n";
            value.forEach((item) => {
              if (item.name) {
                result += `${indent}- FileName: ${item.name}\n`;
              }
            });
          } else {
            processArray(key, value, indentLevel);
          }
        } else if (value instanceof Date) {
          result += `${indent}${formattedKey}: "${formatDate(value)}"\n`;
        } else {
          result += `${indent}${formattedKey}:\n`;
          for (let innerKey in value) {
            processKeyValue(innerKey, value[innerKey], indentLevel + 2, key);
          }
        }
      } else {
        result += `${indent}${formattedKey}: ${value}\n`;
      }
    }

    function cleanObject(obj) {
      delete obj["referenceId"];
      delete obj["id"];
      delete obj["key"];

      if (typeof obj !== "object" || obj === null) return obj;

      for (let key in obj) {
        if (obj[key] && typeof obj[key] === "object") {
          if (obj[key] instanceof Date) {
            obj.effectiveDate = obj[key];
            delete obj[key];
            continue;
          }
          obj[key] = cleanObject(obj[key]);
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key];
          }
        } else if (obj[key] === null || obj[key] === false || obj[key] === "" || obj[key] === undefined) {
          delete obj[key];
        }
      }
      return obj;
    }

    for (let key in obj) {
      processKeyValue(key, obj[key]);
    }

    return result.trim();
  },
  truncate: (str, max) => {
    if (str) {
      return str.length > max ? `${str.substr(0, max - 1)}...` : str;
    }
    return "";
  },
  timestamp: () => Math.round(new Date().getTime() / 1000),
  formatTimestamp: (ts) => {
    const m = moment.unix(ts);
    return m.format("MMMM Do YYYY, h:mm:ss a");
  },
  round: (value, decimals) => {
    decimals = typeof decimals !== "undefined" ? decimals : 15;
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },
  isset: (obj) => typeof obj !== "undefined",
  isEmail: (email) => {
    //const regex = /^(([^<>()\[]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
    //return true;
  },
  getCookie: (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
  },
  isEmpty: (obj) => Object.keys(obj).length === 0,
  sleep: (ms) => new Promise((res) => setTimeout(res, ms)),
  formatNumCommas: (x) => {
    if (!x) {
      return "0";
    }
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    const n = parseFloat(x);
    return n.toLocaleString("en-US");
  },
  formatNumCommas2ndDecimal: (x) => {
    if (!x) {
      return "0";
    }
    const n = parseFloat(x);
    return `${n.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
  },
  formatCurrency: (x) => {
    if (!x) {
      return "$0";
    }
    // return "S$ " + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    const n = parseFloat(x);
    return `$${n.toLocaleString("en-US", { maximumFractionDigits: 2 })}`;
  },
  formatCurrencyCent: (x) => {
    if (!x) {
      return "-";
    }
    // return "S$ " + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    const n = parseFloat(x);
    return `$${n.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
  },
  formatShortHandNumber: (value) => {
    if (value >= 1000000) {
      value = `${value / 1000000}m`;
    } else if (value >= 1000) {
      value = `${value / 1000}k`;
    }
    return value;
  },
  formatTitleCase: (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (word) {
          return word.replace(word[0], word[0].toUpperCase());
        }
        return "";
      })
      .join(" ");
  },
  formatUpperCase: (str) => {
    if (!str) return "";
    return str.toUpperCase();
  },
  formatDateDetail: (str, iso = true) => {
    var d = iso ? new Date(str) : new Date(parseInt(str) * 1);
    //13/2/2022, 2:38:45 AM
    const day = d.toLocaleString("default", { day: "2-digit" });
    const month = d.toLocaleString("default", { month: "short" });
    const year = d.toLocaleString("default", { year: "2-digit" });
    const t = d.toLocaleString("default", { hour: "2-digit", minute: "2-digit", hour12: true });

    return `${day} ${month} ${year}, ${t}`;
  },
  formatDateShort: (str, iso = true) => {
    var d = iso ? new Date(str) : new Date(parseInt(str) * 1);
    // console.log("formatDateShort", d, str);
    const day = d.toLocaleString("default", { day: "2-digit" });
    const month = d.toLocaleString("default", { month: "short" });
    const year = d.toLocaleString("default", { year: "numeric" });
    return `${day} ${month} ${year}`;
  },
  formatDateFull: (str, sup = true) => {
    var d = new Date(parseInt(str) * 1);
    let n = d.toLocaleString("default", { day: "numeric" });
    let n2 = nth(n);
    if (sup) {
      n2 = "<sup>" + n2 + "</sup>";
    }
    const day = n + n2;
    const month = d.toLocaleString("default", { month: "long" });
    const year = d.toLocaleString("default", { year: "numeric" });
    return `${day} ${month} ${year}`;
  },
  formatDateDistanceToNow: (str) => {
    if (!str) {
      return "-";
    }
    const result = _date.formatDistanceToNowStrict(new Date(str), { addSuffix: true });
    return result;
  },
  formatTimeFromNow: (timestamp) => {
    const str = moment.unix(timestamp).fromNow();
    return str;
  },
  formatDateDetailReadable: (str) => {
    const d = str ? _date.format(new Date(str), "do MMM yyyy HH:mm a") : "";
    return d;
  },
  formatTimeReadable: (str) => {
    return _date.format(new Date(str), "HH:mm a");
  },
  formatDateShortReadable: (str) => {
    if (!str) {
      return "-";
    }
    try {
      return _date.format(new Date(str), "do MMM yyyy");
    } catch (e) {
      return "-";
    }
  },

  parseIsoDate: (str) => {
    return _date.parseISO(str);
  },
  formatIsoDate: (d) => {
    return d?.toISOString();
    //return _date.formatISO(d);
  },
  nth: nth,
  isArray: (obj) => Object.prototype.toString.call(obj) === "[object Array]",
  last: (arr) => arr[arr.length - 1],
  recursivelyLowercaseJSONKeys: (obj) => lowerKeys(obj),
  isMobile: () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  },
  getParameterByName: (name, url) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  colorLuminance: (hex, lum) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  },
  /** Capitalize only the first character in whole string */
  capitalizeString: (str) => {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  },
  /** Capitalize all the first character in whole string */
  capitalizeStringAll: (str) => {
    if (!str) {
      return "-";
    }

    let strings = str.split(" ");
    const a = strings.map((string) => string.charAt(0).toUpperCase() + string.slice(1));

    return a.join(" ");
  },
  camelCaseToWords(s) {
    const result = s.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  },
  convertToLocaleTime(time, format) {
    return moment.utc(time).local().format(format);
  },
  getFileExtension(filename) {
    if (filename) {
      const ext = filename.split(".").pop();
      return ext.toLowerCase();
    }
    return "";
  },
  formatDate: (iso) => {
    return iso ? moment(iso).format("DD MMM YYYY") : "-";
  },
  combineString: (...str) => {
    return str.filter((str) => str).join(" ");
  },
  getIndvFullName: (d) => {
    let n = "";
    if (!Object.keys(d).length) {
      return n;
    } else if (d.fullName) {
      n = d?.fullName;
    } else {
      //n = Utils.combineString(d?.firstName, d?.lastName)
      n = [d?.firstName, d?.lastName].filter((str) => str).join(" ");
    }

    return n;
  },
  //ref: https://stackoverflow.com/questions/46795955/how-to-know-scroll-to-element-is-done-in-javascript
  smoothScroll: (elem, options) => {
    return new Promise((resolve, reject) => {
      if (!(elem instanceof Element)) {
        throw new TypeError("Argument 1 must be an Element");
      }
      let same = 0; // a counter
      let lastPos = null; // last known Y position
      // pass the user defined options along with our default
      const scrollOptions = Object.assign({ behavior: "smooth" }, options);

      // let's begin
      elem.scrollIntoView(scrollOptions);
      requestAnimationFrame(check);

      // this function will be called every painting frame
      // for the duration of the smooth scroll operation
      function check() {
        // check our current position
        const newPos = elem.getBoundingClientRect().top;
        // console.log("check lastPos...", lastPos)
        // console.log("check newPos...", newPos)
        // console.log("check same...", same)

        if (newPos === lastPos) {
          // same as previous
          if (same++ > 2) {
            // if it's more than two frames
            if (isAtCorrectPosition(elem, options)) {
              //succeeded
              return resolve(1);
            } else {
              //not succeeded, scroll interaction detected while smooth scrolling
              return resolve(0);
            }
            // return resolve(); // we've come to an halt
          }
        } else {
          same = 0; // reset our counter
          lastPos = newPos; // remember our current position
        }
        // check again next painting frame
        requestAnimationFrame(check);
      }
      function isAtCorrectPosition(elem, options) {
        const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const elemTop = elem.getBoundingClientRect().top;
        return elemTop >= 0 && elemTop <= viewportHeight;
      }
    });
  },
  fileUrlToBase64: async (url) => {
    const storeAccount = useStoreAccount();
    const data = await fetch(url, { headers: { Authorization: storeAccount.user.accessToken, Token: storeAccount.user.idToken } });
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  },
  formatPath: (path) => {
    return path.split("/").filter(Boolean).join("/");
  },
  sortObject: (obj, keys) => {
    return keys.reduce((acc, key) => {
      acc[key] = typeof obj[key] === "object" ? obj[key] : _.toUpper(obj[key]);
      return acc;
    }, {});
  },
  getCountryName: (iso) => {
    const country = countriesData.find((c) => c.iso === iso);
    return country ? _.toUpper(country.name) : _.toUpper(iso);
  },
};
